import React from "react";
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import {Row, Container, Col } from 'react-bootstrap';

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import GenerateLink from "../components/common/site/generate-link"


const Sitemap = () => {
    
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusBurgers(sort: "Sort:asc") {
        Parent_Label
        Parent_Link {
          id
        }
        Sort
        Sub_Menus {
          Label
          Link {
            id
          }
        }
      }
    }
  }
`);

const menus = data.glstrapi.menusBurgers;
    return (
    <>
      <Header />
        <div className="collection-landing">
            <Container>
                <Row>
                    <Col lg={12} className="sitemap-page">
                    <h1>Sitemap</h1>
                    <ul className="sitemap-main-list">
                      {menus.map(( node , i) => {
                        var main_label = node.Parent_Label
                        return <React.Fragment key={i}>
                          {node.Sub_Menus.length === 0 &&
                              <li className="li-main">
                              {node.Parent_Link ?
                              <GenerateLink link={node.Parent_Link} class="" label={node.Parent_Label}>{node.Parent_Label}</GenerateLink> :
                              <>{node.Parent_Label}</> 
                              }
                              </li>
                          }

                          {node.Sub_Menus.length !== 0 &&
                          <li className="li-main">
                            {node.Parent_Link ?
                              <GenerateLink link={node.Parent_Link} class="" label={node.Parent_Label}>{node.Parent_Label}</GenerateLink>  :
                              <>{node.Parent_Label}</> 
                            }
                              <ul className="sub-menu sitemap-sub-menu-list">
                                {node.Sub_Menus.map((Sub_Menus, i) => {
                                  return <li key={i} className='sub-li-list'>
                                    {Sub_Menus.Link ?
                                      <GenerateLink link={Sub_Menus.Link} class="" label={Sub_Menus.Parent_Label}>{Sub_Menus.Label}</GenerateLink> :
                                      <>{Sub_Menus.Label}</>
                                    }
                                    
                                  </li>
                                })}
                                  </ul>
                          </li>
                        }
                        </React.Fragment>
                      })}
                    </ul>
                    </Col>
                </Row>
            </Container>
        </div>
      <Footer />
    </>
    )
}
  
  export default Sitemap
  